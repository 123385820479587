<template>
  <div class="NewsletterSubscribeView">
    <div class="o-container-gray">
      <div v-if="!snackbar.showUpdatedSnackbar">
        <form novalidate class="md-layout" @submit.prevent="handleSubmit">
          <md-card>

            <md-card-content>
              <div :class="getValidationClass('check')" name="check" id="check">
                <md-checkbox v-for="(option, index) in this.choices"
                            :key="index"
                            :name='option.choice'
                            :id='option.choice'
                            class="md-primary"
                            v-model="check"
                            :value="option.duration">{{ $t('newsletter.' + option.choice) }}</md-checkbox>
                <span v-if="!$v.check.required" class="md-error">{{ $t('newsletter.pleaseChoose') }}</span>
            </div>

              <md-field md-clearable :class="getValidationClass('email')">
                <label for="email">{{ $t('newsletter.email') }}</label>
                <md-input v-model.trim="email"
                          type="email"
                          name="email"
                          id="email"
                          autocomplete="email" />
                <span v-if="!$v.email.required" class="md-error">{{ $t('common.auth.pleaseEnterEmail') }}</span>
                <span v-else-if="!$v.email.email" class="md-error">{{ $t('common.auth.incorrectEmail') }}</span>
              </md-field>

              <p class="o-default-body">
                  {{ $t('signInView.personalDataPrivacy') }}
                  {{ $t('signInView.personalDataPrivacyLabelBefore') }}
                  <router-link class="o-default-body" :to="links.privacyPolicy.path">{{ links.privacyPolicy.text }}</router-link>
                  {{ $t('signInView.personalDataPrivacyLabelAfter') }}
              </p>
            </md-card-content>

            <vue-recaptcha ref="invisibleRecaptcha"
                              @verify="onVerify"
                              @expired="onExpired"
                              size="invisible"
                              :sitekey="sitekey">
            </vue-recaptcha>

            <md-card-actions>
              <md-button type="submit" class="md-raised md-primary subscribe-btn">{{ $t('newsletter.subscribe') }}</md-button>
            </md-card-actions>

          </md-card>
        </form>
      </div>
      <div v-else>
        <img src="@/assets/images/checkmark.png"
            alt="Checkmark image"
            class="o-image-medium" />
        <h3 class="o-default-h3 u-strong">{{ $t('newsletter.confirmation') }}</h3>
        <div @click="reloadPage()" class="o-default-body u-green u-text-underline u-cursor-pointer">
            {{ $t('common.reloadPage') }}</div>
      </div>
      <md-snackbar :md-position="snackbar.position" :md-duration="snackbar.duration" :md-active.sync="snackbar.showUpdatedSnackbar" md-persistent>
        <span>{{ $t('newsletter.confirmation') }}</span>
      </md-snackbar>
    </div>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex';
import VueRecaptcha from 'vue-recaptcha';
import {
  FETCH_SUBSCRIBE_DATA,
  NEWSLETTER_REGISTERED_USERS,
  NEWSLETTER_UNREGISTERED_USERS
} from '@/store/actions.type';
import { validationMixin } from 'vuelidate';

import {
  email,
  required
} from 'vuelidate/lib/validators';

export default {
  name: 'NewsletterSubscribeView',
  mixins: [validationMixin],
  components: {
    VueRecaptcha,
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'newsletter'
    ]),
  },
  data() {
    return {
      snackbar: {
        showUpdatedSnackbar: false,
        duration: 4000,
        position: 'center'
      },
      links: {
        privacyPolicy: {
          path: '/privacy-policy',
          text: this.$t('signInView.privacyPolicyLinkText')
        },
      },
      check: null,
      choices: [{choice: 'weekly', duration: 7},
                {choice: 'everyOtherWeek', duration: 14},
                {choice: 'monthly', duration: 30}
               ],
      terms: false,
      email: '',
      sitekey: '6LehkZMUAAAAAOua6fmkshH_FUe0rkw0v45UXjIS', // @todo - to be added in config.js file
    };
  },
  validations: {
    check: {
      required,
    },
    email: {
      required,
      email
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$store.dispatch(FETCH_SUBSCRIBE_DATA)
      .then(() => {
          this.email = this.newsletter.email;
          this.check = this.newsletter.newsletter;
      });
    } else {
      this.email = '';
    }
  },
  methods: {
    savePreferences(response) {
      if (!this.isAuthenticated) {
        var payload = {
          email: this.email,
          newsletter: this.check,
          captchaToken: response
        };
        this.$store.dispatch(NEWSLETTER_UNREGISTERED_USERS, payload)
        .then(() => {
          this.snackbar.showUpdatedSnackbar = true;
        });
      } else {
        payload = {
          email: this.email,
          newsletter: this.check
        };
        this.$store.dispatch(NEWSLETTER_REGISTERED_USERS, payload)
        .then(() => {
          this.snackbar.showUpdatedSnackbar = true;
        });
      }
    },
    handleSubmit() {
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$refs.invisibleRecaptcha.execute();
    },
    onVerify(response) {
      this.$refs.invisibleRecaptcha.reset();

      if (!this.$v.invalid) {
        this.savePreferences(response);
      }
    },
    onExpired() {
      this.$refs.invisibleRecaptcha.reset();

      console.log('Expired')
    },
    getValidationClass(fieldName) {
      const field = this.$v[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.NewsletterSubscribeView {

  .md-card {
    margin-left: auto;
    margin-right: auto;
    padding: 1.6rem;
    width: 66rem;
  }

  .md-primary {
    width: 100%;
  }

  .input-email {
    max-width: 50%;
  }

  .subscribe-btn {
    background-color: $palette-primary-color-four !important;
    margin: auto;
  }

  .o-default-h3 {
    text-align: center;
  }

  .md-error {
    color: $palette-error;
  }
}
</style>
